import React from "react"
import { graphql, StaticQuery } from "gatsby"

import IndexLayout from "../components/index-layout"
import ScrollingHorizontally from "../components/main-page-scroll"

import SEO from "../components/seo"
import APIHelper from "../utils/helpers/api"
import LinkedInTag from 'react-linkedin-insight';
import "../utils/normalize.css"
import "../utils/css/v2.css"

const pkg = require("../../package.json");

class PageIndex extends React.Component {
    constructor(props) {
        super(props);

        const { data: { contentNode: { values = "", meta = [] }}} = this.props;
        const { title = "" } = JSON.parse(values);

        this.state = {
            title: title,
            meta: meta
        };
    }

    async componentDidMount() {          
        console.log("V" + pkg.version);    
        const { data: { contentNode: { identifier = "" }}} = this.props;

        APIHelper.Get(`/contents/id/${identifier}`, (err, response) => {
            if (err) {
                console.log(`Something bad happened while fetching the data\n${err}. Using build content.`);
            } else {
                const { title = "", meta = [] } = response.data;
                this.setState({ title : title, meta : meta });
            }
        })
    }

    render () {
        const { title = "", meta = [] } = this.state, { location = "" } = this.props;

        return (<>
            <IndexLayout location={location} headerActive={"Expertise"}>
                <SEO title={title} meta={meta} useTitleTemplate={false}/>
                <ScrollingHorizontally />
            </IndexLayout>
        </>)
    }
}

const indexQuery = graphql`
  query {
    contentNode(identifier: { eq: "partial_homepage" }) {
      identifier
      values
      meta { 
          content
          name
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <PageIndex location={props.location} props data={data} {...props} />
    )}
  />
)

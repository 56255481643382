import React from "react"
import Header from "./partials/header"

class IndexLayout extends React.Component {
  render() {
    const { children, headerActive, location } = this.props

    return (
      <div className="site-wrapper site-wrapper-main-page" id="site-wrapper">
        <Header headerActive={headerActive} location={location}></Header>
        <div id="site-main">{children}</div>
      </div>
    )
  }
}

export default IndexLayout
